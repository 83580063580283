.skeleton {
  position: relative;
  background-color: var(--gray80);
  overflow: hidden;
}


.skeletonInner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20%;
  animation: load 1s ease-out 0s infinite;
  background: linear-gradient(90deg, var(--gray80) 0%, var(--gray90) 50%, var(--gray80) 100%);
  @keyframes load {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(700%);
    }
  }
}