@import '@/app/variables';

.step {
  height: 326px;
  background: var(--text-color-inverted);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media(max-width: $bp-m) {
    height: auto !important;
    max-width: 100% !important;
    min-width: auto!important;
  }
}

.man {
  width: 290px !important;
  height: 326px !important;
  object-fit: contain;
  border-radius: 10px;

  @media(max-width: $bp-m) {
    width: 100% !important;
    height: auto !important;
  }
}

.number {
  color: hsla(263, 100%, 54%, 1);
  font-weight: 800;
  font-size: 70px;
  line-height: 60px;
}

.text {
  color: var(--text-color);
  font-size: var(--fz-20);
  font-weight: 500;
  margin-top: 30px;
  line-height: 34px;
}

.stepWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 60px;

  @media(max-width: 1370px) {
    flex-wrap: wrap;
  }

  @media(max-width: $bp-m) {
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
}
