@import '@/app/variables';

.partnershipBlock {
  &:not(:last-child) {
    margin-bottom: 200px;

    @media (max-width: $bp-m) {
      margin-bottom: 80px;
    }
  }
}