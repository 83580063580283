@import '@/app/variables';

.sliderWrapper {
  margin-top: 30px;

  @media(max-width: $bp-m) {
    margin-top: 24px;
  }
}

.container {
  padding-bottom: 200px;
  @media(max-width: $bp-m) {
    padding-bottom: 80px;
    overflow: hidden;
  }
}

.btnWrapper {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-bottom: 30px;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  @media(max-width: $bp-m) {
    width: 100vw;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0 20px;
    margin: 0 -20px 16px;
  }
}

.btn {
  background: hsla(263, 100%, 54%, 0.05);
  color: hsla(263, 100%, 54%, 1);
  border-radius: 5px;
  padding: 5px 10px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fz-14);
  font-weight: 500;
  line-height: 24px;

  @media(max-width: $bp-m) {
    white-space: nowrap;
  }
}

.active {
  background: hsla(263, 100%, 54%, 1);
  color: var(--text-color-inverted);
}

.mobShown {
  display: none;
  @media(max-width: $bp-m) {
    display: block !important;
  }
}

.imageDesk {
  @media(max-width: $bp-l) {
    width: 100%;
    height: auto;
  }
}

.deskShown {
  @media(max-width: $bp-m) {
    display: none !important;
  }
}

.sliderBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--text-color-inverted);
  border-radius: 3px;

  @media(max-width: $bp-l) {
    display: none;
  }
}

.sliderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.startBtn {
  background: hsla(263, 100%, 54%, 1);
  color: var(--text-color-inverted);
  height: 61px;
  padding: 15px 42px;
  border-radius: 7px;
  margin: 0 auto;
  display: block;
  font-weight: 500;
  font-size: var(--fz-18);
  line-height: 30px;
  max-width: 360px;
  text-align: center;

  @media(max-width: $bp-m) {
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
  }
}