@import '@/app/variables';

.title {
  margin-bottom: 60px;
  text-align: center;

  @media (max-width: $bp-m) {
    margin-bottom: 24px;
  }
}

.socialsList {
  display: flex;
  justify-content: space-between;

  @media (max-width: $bp-m) {
    flex-direction: column;
    gap: 24px;
  }
}