@import '@/app/variables';

.wrapper {
  width: 50%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: scale(1) translateX(-50%);
    @media(max-width: $bp-m) {
      position: static;
      transform: scale(1) translateX(0);
    }
  }

  @media(max-width: $bp-m) {
    width: 100vw;
    max-width: 325px;
    margin: 10px auto 0;
    display: flex;
    justify-content: center;
  }

}

.wrapper.desktop.customer {
  &:before {
    width: 1039px;
    height: 1039px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='776' height='404' viewBox='0 0 776 404' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='519.5' cy='179.5' r='519.5' fill='%23006CEC' fill-opacity='0.1'/%3E%3Ccircle cx='594.548' cy='182.087' r='444.454' fill='%23006CEC' fill-opacity='0.1'/%3E%3Ccircle cx='666.357' cy='181.439' r='372.643' fill='%23006CEC' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
  }
}

.wrapper.desktop.executor {
  &:before {
    width: 1039px;
    height: 1039px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='776' height='404' viewBox='0 0 776 404' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='519.5' cy='179.5' r='519.5' fill='%23F38E30' fill-opacity='0.1'/%3E%3Ccircle cx='594.548' cy='182.087' r='444.454' fill='%23F38E30' fill-opacity='0.1'/%3E%3Ccircle cx='666.357' cy='181.439' r='372.643' fill='%23F38E30' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
  }
}

.wrapper.mobile.customer {
  &:before {
    width: 382px;
    height: 382px;
    left: 40px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='287' height='249' viewBox='0 0 287 249' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='190.999' cy='190.999' r='190.999' fill='%23006CEC' fill-opacity='0.1'/%3E%3Ccircle cx='218.592' cy='191.951' r='163.408' fill='%23006CEC' fill-opacity='0.1'/%3E%3Ccircle cx='244.994' cy='191.709' r='137.006' fill='%23006CEC' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
  }
}

.wrapper.mobile.executor {
  &:before {
    width: 382px;
    height: 382px;
    left: 40px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='382' height='382' viewBox='0 0 382 382' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='190.999' cy='190.999' r='190.999' fill='%23F38E30' fill-opacity='0.1'/%3E%3Ccircle cx='218.592' cy='191.951' r='163.408' fill='%23F38E30' fill-opacity='0.1'/%3E%3Ccircle cx='244.994' cy='191.709' r='137.006' fill='%23F38E30' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
  }
}