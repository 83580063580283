@import '@/app/variables';

.wrapper {
  font-family: 'Gilroy', sans-serif;
  color: var(--text-color-inverted);
  padding-top: 230px;
  min-height: 100dvh;
  padding-bottom: 60px;
  background: linear-gradient(to right, var(--primary-partner-color), var(--primary-partner-light-color));

  @media (max-width: $bp-m) {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 0;
  }

  > div {
    @media (max-width: $bp-m) {
      margin-top: auto;
    }
  }
}

.title {
  margin-bottom: 30px;
  @media(max-width: $bp-m) {
    font-size: var(--fz-40) !important;
    line-height: 34px !important;
    margin-bottom: 16px;
  }
}

.description {
  margin-bottom: 60px;
  @media (max-width: $bp-m) {
    font-size: var(--fz-18);
    line-height: 21px;
    margin-bottom: 24px;
  }
}

.link {
  display: block;
  color: var(--primary-partner-color);
  background-color: var(--text-color-inverted);
  font-size: var(--fz-18);
  width: 365px;
  height: 61px;
  line-height: 61px;
  text-align: center;
  border-radius: 7px;
  font-weight: 500;
  @media(max-width: $bp-m) {
    height: 55px;
    line-height: 55px;
    width: 100%;
  }
}