@import '@/app/variables';

.form {
  width: 750px;
  margin: 0 auto;

  @media(max-width: $bp-m) {
    width: 100%;
  }
}

.button {
  width: 100%;
  color: var(--text-color-inverted);
  background: var(--primary-partner-color);
  padding: 15px;
  border-radius: 7px;
  font-family: Gilroy, sans-serif;
  font-size: var(--fz-18);
  line-height: 30px;
  font-weight: 500;
}

.inputBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 30px;

  @media(max-width: $bp-m) {
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 24px;
  }
}