@import '@/app/variables';

.imageBox {
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: var(--text-color-inverted);

  @media (max-width: $bp-m) {
    margin-bottom: 8px;
  }
}

.item {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $bp-m) {
    width: auto;
  }
}

.text {
  color: var(--text-color);
  line-height: 27px;
  font-size: var(--fz-24);
  font-family: Gilroy, sans-serif;
  text-align: center;
}