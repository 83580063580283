@import '@/app/variables';

.checkBox {
  display: block;
  cursor: pointer;

  input {
    box-sizing: border-box;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);

    & + div {
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border-radius: 1px;
      transition: 300ms;
      background-color: var(--text-color-inverted);
      border: 1px solid var(--primary-partner-color);

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        border-radius: 1px;
        opacity: 0;
        background-color: var(--primary-partner-color);
        width: 10px;
        height: 10px;
        transition: opacity 300ms;
      }
    }

    &:checked + div:after {
      opacity: 1;
    }
  }
}

.text {
  font-weight: 500;
  padding-left: 26px;

  @media(max-width: $bp-m) {
    font-size: var(--fz-14);
    line-height: 24px;
  }
}