@import "src/app/variables.scss";

.container {
  padding: 80px 20px 16px 20px;

  @media (min-width: $bp-l) {
    padding: 0 16px 0px 16px;
  }
}

.types {
  margin-top: 60px;
  margin-bottom: 60px;

  @media (max-width: $bp-s) {
    margin-bottom: 24px;
  }
}

.slickSlider {
  padding: 0 95px;

  @media (max-width: $bp-xl) {
    padding: 0;
  }

  @media (max-width: $bp-s) {
    margin: 0 -20px;
  }

   div {
     outline: none;
   }
}

.typeWrapper {
  width: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $bp-xl) {
    margin-left: 15px;
  }

  p {
    margin-top: 15px;
    font-size: var(--fz-14);
    color: var(--text-color);
    line-height: 24px;
  }
}

.type {
  width: 120px;
  height: 100px;
  background: var(--text-color-inverted);
  box-shadow: hsla(213, 21%, 14%, 0.05);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  @media (max-width: $bp-l) {
    grid-template-columns: repeat(3, auto);
  }

  @media (max-width: $bp-m) {
    grid-template-columns: 1fr;
  }
}

.slickArrow {
  position: absolute;
  bottom: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  box-shadow: 1px 2px 10px 0px hsla(216, 21%, 14%, 0.1);
  border-radius: 3px;
  background: var(--text-color-inverted);
  z-index: 2;

  svg {
    vertical-align: middle;
  }
}

