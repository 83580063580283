.input {
  display: inline-block;
  width: 100%;
  border: none;
  outline: none !important;
  box-shadow: none;
  font-size: var(--fz-16);
  line-height: 27px;
  color: var(--text-color);
  font-weight: 500;
  border-radius: 7px;
  padding: 15px;

  &::placeholder {
    color: var(--placeholder-color);
    font-weight: 500;
  }
}
