@import '@/app/variables';

.wrapper {
  padding: 60px 0;
  background-color: var(--text-color-inverted);
  border-radius: 10px;

  @media(max-width: $bp-m) {
    padding: 16px;
    margin: 0 20px;
  }
}

.title {
  text-align: center;
  margin-bottom: 30px;
  @media(max-width: $bp-m) {
    margin-bottom: 16px;
  }
}

.link {
  display: block;
  width: 200px;
  color: var(--text-color-inverted);
  background: var(--primary-partner-color);
  margin: 0 auto 60px;

  @media(max-width: $bp-m) {
    margin-bottom: 32px;
  }
}