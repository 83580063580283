@import '@/app/variables';

.container {
  padding-bottom: 200px;

  @media(max-width: $bp-m) {
    padding-bottom: 80px;
  }
}

.wrapper {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  row-gap: 30px;

  @media(max-width: $bp-m) {
    margin-top: 25px;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}

.condition {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;

  @media(max-width: $bp-m) {
    margin-bottom: 16px;
    gap: 16px;
  }
}

.title {
  font-weight: 500;
  font-size: var(--fz-18);
  line-height: 30px;
  color: var(--text-color);

  @media(max-width: $bp-m) {
    line-height: 22px;
  }
}

.description {
  color: var(--text-color);
  line-height: 34px;
  font-size: var(--fz-20);
  font-weight: 500;
  max-width: 750px;
}